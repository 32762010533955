var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mw-100",staticStyle:{"width":"26.25rem","margin":"0 auto"}},[_c('div',{staticClass:"card text-center"},[_c('div',{staticClass:"card-body mx-6 mb-6 mt-5 p-0"},[_c('h1',{staticClass:"card-title mb-5"},[_vm._v("Change Password")]),_c('form',{staticClass:"text-left mw-1000",attrs:{"id":"changePw","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.changePassword.apply(null, arguments)}}},[_vm._m(0),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"sr-only",attrs:{"for":"oldPassword"}},[_vm._v("Old Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.oldPassword.$model),expression:"$v.form.oldPassword.$model"}],staticClass:"form-control form-control-lg mb-5 text-center",class:{
                    'is-valid': !_vm.$v.form.oldPassword.$error && _vm.$v.form.oldPassword.$dirty,
                    'is-invalid': _vm.$v.form.oldPassword.$error
                  },attrs:{"type":"password","id":"oldPassword","name":"oldPassword","placeholder":"Old Password","autocomplete":"off","maxlength":"64"},domProps:{"value":(_vm.$v.form.oldPassword.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.oldPassword, "$model", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"sr-only",attrs:{"for":"newPassword"}},[_vm._v("New Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.newPassword.$model),expression:"$v.form.newPassword.$model"}],staticClass:"form-control form-control-lg mb-5 text-center",class:{
                    'is-valid': !_vm.$v.form.newPassword.$error && _vm.$v.form.newPassword.$dirty,
                    'is-invalid': _vm.$v.form.newPassword.$error
                  },attrs:{"type":"password","id":"newPassword","name":"newPassword","placeholder":"New Password","autocomplete":"off","maxlength":"64"},domProps:{"value":(_vm.$v.form.newPassword.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.newPassword, "$model", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"sr-only",attrs:{"for":"confirmPassword"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.confirmPassword.$model),expression:"$v.form.confirmPassword.$model"}],staticClass:"form-control form-control-lg mb-5 text-center",class:{
                    'is-valid': !_vm.$v.form.confirmPassword.$error && _vm.$v.form.confirmPassword.$dirty,
                    'is-invalid': _vm.$v.form.confirmPassword.$error
                  },attrs:{"type":"password","id":"confirmPassword","name":"confirmPassword","placeholder":"Confirm Password","autocomplete":"off","maxlength":"64"},domProps:{"value":(_vm.$v.form.confirmPassword.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.confirmPassword, "$model", $event.target.value)}}})])]),_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"type":"submit","disabled":_vm.$v.form.$invalid}},[_vm._v("Submit")]),_c('br'),_c('br'),_c('br')])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"mb4 text-center"},[_vm._v(" Passwords must be at least 6 characters, no more than 20 characters, and must include at least one upper case letter, one lower case letter, and one numeric digit. New Password must be different than Old Password. ")])])])
}]

export { render, staticRenderFns }