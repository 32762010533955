<template>
  <div class="mw-100" style="width: 26.25rem; margin:0 auto;">
    <div class="card text-center">
      <div class="card-body mx-6 mb-6 mt-5 p-0">

        <h1 class="card-title mb-5">Change Password</h1>

        <form id="changePw" class="text-left mw-1000" autocomplete="off" @submit.prevent="changePassword">

          <div class="col-sm-12">
            <div class="form-group">
              <div class="mb4 text-center">
                Passwords must be at least 6 characters, no
                more than 20 characters, and must include at
                least one upper case letter, one lower case
                letter, and one numeric digit. New Password
                must be different than Old Password.
              </div>
            </div>
          </div>

          <div class="col-sm-12">
              <div class="form-group">
                  <label for="oldPassword" class="sr-only">Old Password</label>
                  <input 
                    type="password" 
                    id="oldPassword" 
                    name="oldPassword" 
                    placeholder="Old Password" 
                    autocomplete="off" 
                    v-model="$v.form.oldPassword.$model" 
                    maxlength="64"
                    class="form-control form-control-lg mb-5 text-center"
                    :class="{
                      'is-valid': !$v.form.oldPassword.$error && $v.form.oldPassword.$dirty,
                      'is-invalid': $v.form.oldPassword.$error
                    }"
                  />
              </div>
          </div>

          <div class="col-sm-12">
              <div class="form-group">
                  <label for="newPassword" class="sr-only">New Password</label>
                  <input 
                    type="password" 
                    id="newPassword" 
                    name="newPassword" 
                    placeholder="New Password" 
                    autocomplete="off"
                    v-model="$v.form.newPassword.$model" 
                    maxlength="64"
                    class="form-control form-control-lg mb-5 text-center"
                    :class="{
                      'is-valid': !$v.form.newPassword.$error && $v.form.newPassword.$dirty,
                      'is-invalid': $v.form.newPassword.$error
                    }"
                  />
              </div>
          </div>

          <div class="col-sm-12">
              <div class="form-group">
                  <label for="confirmPassword" class="sr-only">Confirm Password</label>
                  <input 
                    type="password" 
                    id="confirmPassword" 
                    name="confirmPassword" 
                    placeholder="Confirm Password" 
                    autocomplete="off" 
                    v-model="$v.form.confirmPassword.$model" 
                    maxlength="64"
                    class="form-control form-control-lg mb-5 text-center"
                    :class="{
                      'is-valid': !$v.form.confirmPassword.$error && $v.form.confirmPassword.$dirty,
                      'is-invalid': $v.form.confirmPassword.$error
                    }"
                  />
              </div>
          </div>

          <button type="submit" :disabled="$v.form.$invalid" class="btn btn-primary btn-block">Submit</button>
          <br><br><br>
        </form>
        
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { pcMixin } from '@/mixins/pcMixin.js';
import { apiMixin } from '@/mixins/apiMixin.js';

import { helpers } from 'vuelidate/lib/validators';
import { required } from 'vuelidate/lib/validators';
import { sameAs } from 'vuelidate/lib/validators';
import { not } from 'vuelidate/lib/validators';

// Passwords must be at least 6 characters,
// no more than 20 characters,
// and must include at least one upper case letter,
// one lower case letter, and one numeric digit.
const pwrules = helpers.regex('alpha', /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/);

export default {
  name: 'ChangePasswordView',

  mixins: [ pcMixin, apiMixin ],

  data() {
    return {
      form: {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null
      }
    }
  },

  validations: {
    form: {
      oldPassword: {
        required
      },
      newPassword: {
        required,
        pwrules,
        notSameAsOldPassword: not(sameAs('oldPassword'))
      },
      confirmPassword: {
        sameAsNewPassword: sameAs('newPassword')
      }
    }
  },

  methods: {
    changePassword() {
      this.pcSpinnerShow();
      this.apiChangePassword(this.form.oldPassword, this.form.newPassword)
      .then(() => {
        this.pcModal("Password changed successfully")
        .then(() => { this.$router.push({ name: 'PersonalInformationRoute' }) })
      })
      .catch((err) => {
        if (err.response.status == 401)
          this.pcModal("Old password is incorrect").finally()
        else
          this.handleError(err)
      })
      .finally(() => { this.pcSpinnerHide() })
    }
  }
}
</script>
